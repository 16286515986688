// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-Terminal__terminal___3ShUg {\n  --input-height: 40px;\n  background-color: #000;\n  padding: var(--input-height) 20px;\n  border-radius: 5px;\n  width: 100%;\n}\n.src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalHeader___2pF4i {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: var(--input-height);\n  padding: 10px 20px;\n  background: #000;\n  border-bottom: 1px solid #333;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}\n.src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalBody___27aBd {\n  padding: 20px 0px;\n  min-height: calc(100vh - calc(var(--input-height) * 2));\n}\n.src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalBody___27aBd .src-styles-Terminal__terminalOutput___vX96x {\n  display: grid;\n  grid-gap: var(--grid-gap-lg);\n  margin-bottom: 10px;\n}\n.src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalBody___27aBd .src-styles-Terminal__terminalInput___fAvN0 {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: var(--input-height);\n  padding: 10px;\n  border: none;\n  background: none;\n  color: var(--terminal-green);\n  font-size: 16px;\n  outline: none;\n  background: #000;\n  border-top: 1px solid #333;\n}\n@media (max-width: 768px) {\n  .src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalHeader___2pF4i {\n    font-size: 14px;\n  }\n  .src-styles-Terminal__terminal___3ShUg .src-styles-Terminal__terminalInput___fAvN0 {\n    font-size: 14px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"terminal": "src-styles-Terminal__terminal___3ShUg",
	"terminalHeader": "src-styles-Terminal__terminalHeader___2pF4i",
	"terminalBody": "src-styles-Terminal__terminalBody___27aBd",
	"terminalOutput": "src-styles-Terminal__terminalOutput___vX96x",
	"terminalInput": "src-styles-Terminal__terminalInput___fAvN0"
};
module.exports = exports;
