// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-Contact__contact___bgf-T {\n  display: grid;\n  grid-gap: var(--grid-gap);\n}\n.src-styles-Contact__contact___bgf-T a {\n  color: var(--terminal-green);\n  text-decoration: underline;\n}\n.src-styles-Contact__contact___bgf-T a:hover {\n  text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"contact": "src-styles-Contact__contact___bgf-T"
};
module.exports = exports;
