// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-Home__home___ibsIr {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: left;\n  min-height: 100vh;\n  background-color: #000;\n}\n.src-styles-Home__homeContent___31T9H {\n  margin-top: 20px;\n  width: 80%;\n  max-width: 800px;\n  margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"home": "src-styles-Home__home___ibsIr",
	"homeContent": "src-styles-Home__homeContent___31T9H"
};
module.exports = exports;
