// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-Resume__resume___1NT46 {\n  display: grid;\n  grid-gap: var(--grid-gap-lg);\n  width: 100%;\n  overflow-x: scroll;\n  padding: calc(var(--grid-gap)) 0px;\n}\n.src-styles-Resume__resume___1NT46 section,\n.src-styles-Resume__resume___1NT46 div {\n  grid-gap: var(--grid-gap);\n}\n.src-styles-Resume__resume___1NT46 section article,\n.src-styles-Resume__resume___1NT46 div article {\n  padding: var(--grid-gap) 0px;\n}\n.src-styles-Resume__resume___1NT46 p,\n.src-styles-Resume__resume___1NT46 ul {\n  line-height: 26px;\n}\n.src-styles-Resume__resume___1NT46 pre,\n.src-styles-Resume__resume___1NT46 code {\n  width: 100%;\n}\n.src-styles-Resume__resume___1NT46 hr {\n  border-top: 1px dotted;\n}\n", ""]);
// Exports
exports.locals = {
	"resume": "src-styles-Resume__resume___1NT46"
};
module.exports = exports;
