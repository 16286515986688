// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-styles-AskGPT__askGpt___384Qe {\n  display: grid;\n  grid-gap: var(--grid-gap);\n  padding: 16px 0px;\n}\n.src-styles-AskGPT__askGpt___384Qe .src-styles-AskGPT__inputArea___2sRNd {\n  display: flex;\n  flex-direction: row;\n  align-items: end;\n  justify-content: space-between;\n}\n.src-styles-AskGPT__askGpt___384Qe .src-styles-AskGPT__askGptInput___pbApP {\n  width: 80%;\n  margin-bottom: 10px;\n  border: none;\n  background: none;\n  font-size: 16px;\n  outline: none;\n}\n.src-styles-AskGPT__askGpt___384Qe .src-styles-AskGPT__askGptButton___1qV1W {\n  background: transparent;\n  border: none;\n  color: var(--terminal-green);\n  cursor: pointer;\n  text-decoration: underline;\n}\n.src-styles-AskGPT__askGpt___384Qe .src-styles-AskGPT__askGptButton___1qV1W:hover {\n  background-color: #444;\n}\n.src-styles-AskGPT__askGpt___384Qe .src-styles-AskGPT__askGptResponse___6goyM {\n  margin-top: 20px;\n  white-space: pre-wrap;\n}\n.src-styles-AskGPT__askGpt___384Qe div {\n  display: grid;\n  grid-gap: 8px;\n}\n.src-styles-AskGPT__askGpt___384Qe div ul,\n.src-styles-AskGPT__askGpt___384Qe div ol {\n  display: grid;\n  grid-gap: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"askGpt": "src-styles-AskGPT__askGpt___384Qe",
	"inputArea": "src-styles-AskGPT__inputArea___2sRNd",
	"askGptInput": "src-styles-AskGPT__askGptInput___pbApP",
	"askGptButton": "src-styles-AskGPT__askGptButton___1qV1W",
	"askGptResponse": "src-styles-AskGPT__askGptResponse___6goyM"
};
module.exports = exports;
